const fr = {
  menu: {
    products: "produits",
    material: "matière",
    lining: "revêtement mural",
    custom: "sur-mesure",
    inspirations: "inspirations",
    recycled: "textile recyclé",
    recycledTextile: "textile & écologie",
    recycledCollaboration: "collaboration",
    about: "à propos",
    catalog: "catalogue",
    services: "services",
    samples: "échantillons",
    confirmation: "confirmation",
    contact: "contact",
    legal: "mentions légales",
    newsletter: "Newsletter",
    newsletterPlaceholder: "Entrez votre adresse e-mail",
    notfound: "Page introuvable",
  },
  home: {
    title: "Créateur de matériau en textile recyclé",
    subtitle: "Écologique, acoustique, esthétique",
    commitLabel: "Notre engagement",
    commitTitle: "Valoriser les déchets textiles et préserver notre environnement",
    commitText:
      "{name} est un créateur de matériaux acoustiques en textile recyclé pour l’architecture et le design. Tous nos produits sont fabriqués en France et sont composés à plus de 70% de fibres recyclées issues de l’industrie et de l’habillement.",
    france: "Made in\nFrance",
    textile: "Textile\nrecyclé",
    sound: "Absorbant\nacoustique",
    light: "Léger\nfacile à poser",
    applicationsLabel: "Les applications",
    applicationsTitle: "Un revêtement mural et plus encore…",
    applicationsText:
      "Décliné en plusieurs teintes et motifs, nos matériaux s’appliquent en revêtement mural dans les espaces intérieurs qui ont des besoins acoustiques et esthétiques. Ils peuvent également être utilisés pour d’autres créations (design, art, événementiel…).",
    catalog: "Accéder au catalogue",
    wallLabel: "Revêtement mural",
    wallTitle: "La gamme de motifs",
    customLabel: "Service personnalisé",
    customTitle: "Découpe sur-mesure",
    customText:
      "Au delà de notre gamme, un service de découpe sur-mesure du matériau est proposé. Grâce à la découpe numérique du produit, les formes sont personnalisables et s’adaptent à chaque univers de projet.",
    laser: "Découpe\nnumérique",
    reinventTitle: "Réinventer le recyclage textile par le design",
    reinventText:
      "Une longue expérience de recherche et développement mise au service d’une filière et des préoccupations environnementales.",
  },
  products: {
    materialTitle: "matière",
    materialSubtitle: "du textile recyclé à l’aspect minéral",
    materialLabel: "Nos teintes",
    liningTitle: "revêtement mural",
    liningSubtitle: "acoustique et écologique",
    liningLabel: "Notre gamme de motifs",
    customTitle: "sur-mesure",
    customSubtitle: "découpe personnalisée",
    customLabel: "Nos services",
    serviceCutting: "Découpe\nsur-mesure",
    serviceShapes: "Création\nde formes",
    serviceConsulting: "Conseils sur\nvos projets",
  },
  material: {
    reference: "Référence",
    specs: "Spécifications",
    catalog: "Télécharger le catalogue",
    depositsLabel: "Les gisements",
    depositsTitle: "Le textile recyclé comme ressource",
    depositsText:
      "Les fibres textiles sont issues du recyclage des chutes de productions, de vêtements de travail ou de l’habillement. Les textiles sont d’abord effilochés puis feutrés afin d’obtenir le matériau.",
    name: "Nom",
  },
  lining: {
    subtitle: "nos motifs",
    selectMaterial: "Sélectionnez une matière",
    selectPattern: "Sélectionnez un motif",
    selectFormat: "Sélectionnez un format",
    material: "Matière",
    pattern: "Motif",
    name: "Nom",
    infos: "Plus d’infos",
    reference: "Référence motif",
    format: "Format",
    density: "Modules par m2",
    pieces: "pièces",
    size: "Dimensions des formes",
    download: "Informations et outils de conception",
  },
  custom: {
    label: "Découpe sur mesure",
    title: "Conception et développement de découpes spécifiques",
    text:
      "Au delà de notre gamme de motifs, {name} se découpe également sur mesure pour s’adapter au mieux à vos projets. Le matériau se travaille à l’aide d’une découpe numérique selon différentes techniques (laser ou cutter) en fonction des finitions souhaitées.",
    formats: "Formats de découpe",
    specs: "Spécifications",
    standard: "Format standard",
    maximum: "Format maximum",
  },
  inspirations: {
    title: "Une matière à investir",
    text:
      "Nos matériaux s’appliquent en revêtement mural dans les espaces intérieurs, mais peuvent également être utilisés pour toutes vos créations : design, art, événementiel…",
  },
  recycled: {
    textile: "Le textile recyclé,\nun gisement pour la création",
    textileText:
      "Chutes de productions de l’industrie, vêtements, linge de maison, tenues professionnelles… les cycles de production et de consommation produisent chaque année des tonnes de matières textiles hors d’usage encore trop peu valorisées.",
    text:
      "Aujourd’hui, les fibres textiles recyclées sont surtout valorisées dans des usages techniques (rembourrage, isolation, bitume…). Elles sont essentiellement utilisées comme une matière cachée.\nCe contexte constitue un enjeu de design passionnant : repérer le potentiel esthétique résidant dans ces matières trop peu considérées afin d’augmenter leur valeur et de leur inventer de nouveaux usages.",
    stat: "Un européen consomme en moyenne 26 kg de textile chaque année.",
    source: "Source : European Environment Agency",
    collaboration: "Vous avez des déchets textiles, nous avons un savoir-faire.",
    collaborationText:
      "{name} met ses compétences d’éco-design et son savoir-faire en matière de valorisation du textile aux service des entreprises. Nous proposons aux fabricants et aux marques des collaborations pour les accompagner dans la valorisation de portions de leur gisement afin de développer des solutions sur-mesure.",
    resources: "Vos rebuts sont des ressources",
    resourcesText:
      "Nos collaborations peuvent donner naissance à de nouveaux produits : matériau d’architecture pour habiller les bureaux ou les boutiques de la marque, une gamme de mobiliers ou d’objets, ou encore des éléments scénographiques ou événementiels…\n\nIl s’agit de faire ensemble la démonstration que des débouchés à forte valeur ajoutée existent dans le domaine du recyclage, à partir de matière encore trop peu valorisée.",
    contactTitle: " Vous cherchez une solution pour valoriser vos textiles ?",
    contactText:
      "Nous mettons nos compétences et notre expérience de recherche et développement pour élaborer des solutions de valorisation pour vos déchets textiles.",
    contactLink: "Contactez-nous pour plus d’informations.",
  },
  about: {
    title: "Réinventer le recyclage textile par le design",
    text:
      "{name} est un créateur de matériau en textile recyclé pour l’architecture et le design. Tout nos matériaux sont fabriqués en France à partir de fibres recyclées provenant de France et de Belgique.\n\nNous concevons des matériaux au service des concepteurs et créateurs, pour favoriser des projets et des aménagements plus respectueux de l’environnement.",
    origin: "À l’origine du projet",
    agencies:
      "L’agence de design et d’architecture intérieure Premices and co est à l’origine du projet {name}. L’agence rassemble Camille Chardayre, Amandine Langlois et Jérémie Triaire, trois designers formés à l’école Boulle, qui depuis 2013 mettent leurs compétences en design au service des enjeux de l’économie circulaire.\nL’agence se déploie sur trois activités : la conception, la sensibilisation et la fabrication. Chacune de ces activités sert à leur manière la mission principale de l’agence : créer en employant comme des ressources les rebuts produits par notre société.",
    caption: "De Gauche à droite : Camille Chardayre, Jérémie Triaire et Amandine Langlois",
    partners: "Nos partenaires",
    partnersText:
      "La recherche et développement de ce projet a été possible grâce au soutien de différents organismes qui lui ont permis de voir le jour.",
  },
  catalog: {
    title: "Télécharger catalogue",
    text:
      "Téléchargez depuis cette page toutes les informations relatives à nos produits. Pour toutes demandes complémentaires n’hésitez pas à nous contacter.",
    // categories:
    catalogue: "Catalogue produit et services",
    guide: "Guide et outils",
    entretien: "Entretien et maintenance",
    conception: "Outils de conception",
  },
  services: {
    title: "Notre bureau d’études accompagne vos projets de A à Z.",
    text:
      "Etudes architecturales, créations de motifs, conseils en acoustique, finitions et applications du matériau : notre bureau d’études vous accompagne de l’origine jusqu’à l’aboutissement de vos projets.\n\nUne équipe de designers et d’architectes d’intérieurs est à votre disposition pour vous accompagner.",
    title1: "Conception et mise en oeuvre intégrée",
    text1:
      "Nos designers et architectes d’intérieur peuvent vous accompagner de la conception de votre projet à la mise en oeuvre du matériau {name}.",
    title2: "Architecture intérieure et design",
    text2:
      "Au delà de la mise en oeuvre du matériau {name}, nous pouvons mettre nos compétences à votre service pour proposer un projet d’aménagement global et cohérent.",
    title3: "Recherche et developpement",
    text3: "Vous avez un gisement de matériaux que vous souhaitez valoriser par une démarche de design.",
    bottomTitle: "Vous cherchez une solution pour valoriser vos textiles ?",
    bottomText:
      "{name} met ses compétences de recherche et développement à votre service pour des collaborations sur la valorisation pour vos déchets textiles.",
    bottomLink: "En savoir plus sur les collaborations",
  },
  contact: {
    title: "Contacts\net informations",
    text:
      "{name} est un fabricant qui assure la vente en direct de ses matériaux partout en France et en Europe.\nPour toutes questions sur nos produits, contactez-nous.",
    attachment: "Joindre un fichier",
    sent: "Votre message a bien été envoyé",
  },
  samples: {
    title: "Commandez\nnos échantillons",
    tints: "Noms des teintes",
    format: "Format échantillon",
    price: "Tarif",
    withoutVat: " HT",
    usage: "Usage",
    quantity: "Qté",
    total: "Total",
    shipping: "Livraison",
    shippingDays: "{min} à {max} jours ouvrés",
    totalWithoutVat: "Total HT",
    totalWithVat: "Total TTC",
  },
  confirmation: {
    title: "Merci pour votre commande",
    text: "Vos échantillons seront envoyés dans les plus brefs délais.",
  },
  input: {
    name: "Nom",
    firstname: "Prénom",
    lastname: "Nom",
    company: "Société",
    profile: "Profil",
    email: "Adresse e-mail",
    phone: "Téléphone",
    address: "Adresse",
    postcode: "Code postal",
    city: "Ville",
    country: "Pays",
    subject: "Objet de votre message",
    message: "Votre message",
    project: "Décrivez votre projet brièvement (facultatif)",
    send: "Envoyer",
    sending: "Envoi…",
    validate: "Valider",
    validating: "Validation…",
  },
}

export default fr
