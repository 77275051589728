// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-catalog-tsx": () => import("./../../../src/pages/catalog.tsx" /* webpackChunkName: "component---src-pages-catalog-tsx" */),
  "component---src-pages-confirmation-tsx": () => import("./../../../src/pages/confirmation.tsx" /* webpackChunkName: "component---src-pages-confirmation-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inspirations-tsx": () => import("./../../../src/pages/inspirations.tsx" /* webpackChunkName: "component---src-pages-inspirations-tsx" */),
  "component---src-pages-legal-tsx": () => import("./../../../src/pages/legal.tsx" /* webpackChunkName: "component---src-pages-legal-tsx" */),
  "component---src-pages-products-custom-tsx": () => import("./../../../src/pages/products/custom.tsx" /* webpackChunkName: "component---src-pages-products-custom-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-recycled-collaboration-tsx": () => import("./../../../src/pages/recycled/collaboration.tsx" /* webpackChunkName: "component---src-pages-recycled-collaboration-tsx" */),
  "component---src-pages-recycled-textile-tsx": () => import("./../../../src/pages/recycled/textile.tsx" /* webpackChunkName: "component---src-pages-recycled-textile-tsx" */),
  "component---src-pages-samples-tsx": () => import("./../../../src/pages/samples.tsx" /* webpackChunkName: "component---src-pages-samples-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-templates-inspiration-tsx": () => import("./../../../src/templates/inspiration.tsx" /* webpackChunkName: "component---src-templates-inspiration-tsx" */),
  "component---src-templates-lining-tsx": () => import("./../../../src/templates/lining.tsx" /* webpackChunkName: "component---src-templates-lining-tsx" */),
  "component---src-templates-material-tsx": () => import("./../../../src/templates/material.tsx" /* webpackChunkName: "component---src-templates-material-tsx" */)
}

