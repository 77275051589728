import type { GatsbyBrowser } from "gatsby"

import React, { useState, createContext, useContext, useEffect } from "react"
import { IntlProvider } from "react-intl"

import messages from "src/intl"

const DEFAULT_LANG = "fr"

interface IObj {
  nodes: Node[]
}

interface ILangContext {
  lang: Lang
  setLang: (lang: Lang) => void
  getNodes: <T extends Node>(obj: IObj) => T[]
}

const LangContext = createContext<ILangContext>({
  lang: DEFAULT_LANG,
  setLang: () => {
    // shell
  },
  getNodes: () => [],
})

export const useLang = () => useContext(LangContext)

const Root: React.FC = ({ children }) => {
  const [lang, setLang] = useState<Lang>(DEFAULT_LANG)

  useEffect(() => {
    // setLang(navigator.language.substr(0, 2) === "fr" ? "fr" : "en")
    // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
    // document.documentElement.style.setProperty("--height", `${window.innerHeight}px`)
  }, [])

  function getNodes<T extends Node>(obj: IObj) {
    return obj.nodes.filter(({ node_locale }) => node_locale.startsWith(lang)) as T[]
  }

  return (
    <LangContext.Provider value={{ lang, setLang, getNodes }}>
      <IntlProvider locale={lang} messages={messages[lang]}>
        {children}
      </IntlProvider>
    </LangContext.Provider>
  )
}

const Wrapper: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  // https://www.gatsbyjs.org/docs/browser-apis/#wrapRootElement
  return <Root>{element}</Root>
}

export default Wrapper
