export const COLORS = {
  black: "#000000",
  dark: "#2E2E2E",
  grey: "#969696",
  light: "#EEEEEE",
  white: "#FFFFFF",
}

export const SIZES = {
  header: 70,
  headerFull: 110,
  headerSubmenu: 20,
  margin: 45,
  mobileMargin: 30,
  maxWidth: 1400,
}
