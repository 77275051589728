import fr from "./fr"
import en from "./en"

const objects = { fr, en }

const messages: Record<string, Record<string, string>> = {}

for (const lang in objects) {
  messages[lang] = {}
  const obj = objects[lang]
  for (const category in obj) {
    const translations = obj[category]
    for (const key in translations) {
      messages[lang][`${category}.${key}`] = translations[key]
    }
  }
}

export default messages
