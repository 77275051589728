const en = {
  menu: {
    products: "products",
    inspirations: "inspirations",
    recycled: "recycled textile",
    recycledTextile: "textile & ecology",
    recycledCollaboration: "collaboration",
    about: "about",
    catalog: "catalog",
    services: "services",
    contact: "contact",
  },
}

export default en
