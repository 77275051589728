import { css } from "styled-components"

import { COLORS } from "./constants"

export default css`
  body {
    font-family: Jost, sans-serif;
    letter-spacing: 1px;
    touch-action: manipulation; /* to remove touch delays */
    -webkit-tap-highlight-color: transparent;
  }
  body.noscroll {
    width: 100%;
    position: fixed;
  }
  h1,
  h2 {
    font-family: Amiri, serif;
    font-weight: normal;
  }
  h1 {
    font-size: 4em;
    line-height: 1em;
    margin: 0.2em 0;
  }
  h3 {
    text-transform: uppercase;
    font-size: 1.3em;
    letter-spacing: 4px;
  }
  h4 {
    text-transform: uppercase;
    font-size: 0.85em;
    letter-spacing: 2px;
  }
  button {
    cursor: pointer;
    letter-spacing: 1px;
    border: none;
    color: inherit;
    padding: 0;
    background: none;
    font-weight: inherit;
    line-height: inherit;
  }
  button,
  select {
    outline: none;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  ul {
    padding-inline-start: 0;
  }
  li {
    list-style: none;
  }
  sup {
    font-size: 0.5em;
    line-height: 1em;
  }
  input,
  textarea,
  select {
    background-color: transparent;
    border: none;
    outline: none;
    letter-spacing: inherit;
    &::placeholder {
      color: ${COLORS.grey};
    }
  }
  figure {
    margin: 0;
  }
  @media (max-width: 799px) {
    h1 {
      font-size: 3em;
    }
  }
  .contrast {
    transition: filter 300ms ease-in-out;
    &:hover {
      filter: contrast(1.2);
    }
  }
`
